import { graphql } from "gatsby";
import { block } from "bem-cn";

import Layout from "layouts/";
import Heading from "components/Heading";
import Title from "components/Title";
import PartnerLogo from "components/PartnerLogo";
import MediumContainer from "components/Container/_medium";

import {
  insideLink,
  outsideLink,
  renderAst,
} from "components/markDownComponents";

const renderComToolkit = {
  "inside-link": insideLink,
  a: outsideLink,
};

const faqRenderCom = renderAst(renderComToolkit);

import "./style.scss";

const cn = block("about-company-page");

const AboutCompanyPage = ({ data }) => {
  const { htmlAst } = data.markdownRemark;
  const { title, seo, partnersLogo } = data.markdownRemark.frontmatter;

  return (
    <Layout seo={seo}>
      <MediumContainer className={cn("wrap")}>
        <Heading color="general" weight="bold" className={cn("title")}>
          {title}
        </Heading>
        {faqRenderCom(htmlAst)}
				<Title level={2} className={cn('partners-title')}>
					Our Clients
				</Title>
				<ul className={cn('partners-wrap')}>
					{partnersLogo.map((logo) => (
						<li key={logo.name} className={cn('logo-wrap')}>
							<PartnerLogo {...logo} />
						</li>
					))}
				</ul>
      </MediumContainer>
    </Layout>
  );
};

export default AboutCompanyPage;

export const pageQuery = graphql`
	query getAboutCompanyPageData {
		markdownRemark(frontmatter: { slug: { eq: "/about-company" } }) {
      htmlAst
			frontmatter {
				partnersLogo {
					link
					image
					name
				}
				title
				seo {
					title
					link
					description
				}
			}
    }
  }
`;
