import { memo } from 'react';
import PropTypes from 'prop-types';
import Image from "components/Image";

const PartnerLogo = ({name, link, image}) => (
	<a target="_blank" href={link} rel="noopener noreferrer nofollow">
		<Image src={`partners/${image}`} alt={name} />
	</a>
)

PartnerLogo.propTypes = {
	name: PropTypes.string.isRequired,
	link: PropTypes.string,
	image: PropTypes.string.isRequired,
};

export default memo(PartnerLogo);
